<template>
<div style="width:100%">
<v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat>
        <span v-if="item.listado.descripcion" :style="{'color': colores.primario}" style="font-size:15px"><v-icon :color="colores.primario">mdi-account</v-icon>{{ (item.listado.descripcion)? item.listado.descripcion : null }}</span>
        <v-spacer></v-spacer>
        <span v-if="item.created_at" :style="{'color': colores.primario}" style="font-size:15px"><v-icon :color="colores.primario">mdi-calendar</v-icon>{{ rec_function_fecha_colombia(item.created_at) }}</span>
        <!-- <v-btn @click="abrirNotificacion" icon><v-icon :color="colores.primario">mdi-email</v-icon></v-btn> -->
      </v-toolbar>
      <v-toolbar dense flat>
        <span v-if="item.replica_id !== null" :style="{'color': colores.primario}" style="font-size:15px"><v-icon :color="colores.primario">mdi-content-duplicate</v-icon>RÉPLICA</span>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" md="1" align-self="center" class="text-center">
            <v-avatar  v-if="item.listado.descripcion" :color="colores.primario" size="40" class="white--text">{{ (item.listado.descripcion)? item.listado.descripcion.substr(0,1) : null }}</v-avatar>
          </v-col>
          <v-col cols="12" md="11">
            <br>
            <v-row no-gutters>
              <v-col cols="12"></v-col>
            </v-row>
            <v-row no-gutters v-if="item.listado.descripcion">
              <v-col cols="12">
                <v-card>
                    <v-card-text>
                      <logsCierreData :item="item" :colores="colores" ></logsCierreData>
                    </v-card-text>
                  </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
</v-sheet>
</div>
</template>

<script>
// import formNotificaciones from '@/components/crm/form_notificacionesComponent.vue'

import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'lectura_logs_CierreComponent',
  components: {
    logsCierreData: () => import('@/components/crm/lectura_logs_Cierre_DataComponent.vue')
  },
  mixins: [recFunciones],
  props: ['colores', 'item', 'index']
}
</script>
